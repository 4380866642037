<template>
  <div class="mb-2">
    <small @click="click">
      {{text | formatLongText(limit, open)}}
      <a
        v-if="showMoreVisible"
        class="showMoreLink"
      ><strong :style="coloredFonts">show {{showMoreText}}</strong></a>
    </small>
  </div>
</template>
<script>
export default {
  props: {
  },
  props: [
    "text",
    "limit",
    "coloredFonts",
  ],
  methods: {
    click() {
      this.open = !this.open;
    }
  },
  computed: {
    showMoreText() {
      if (this.open) {
        return 'less';
      } else {
        return 'more';
      }
    },
    showMoreVisible() {
      if (this.text && this.text.length > this.limit) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      open: false,
    }
  },
  filters: {
    formatLongText(text, limit, open) {
      if (!open && text && text.length > limit) {
        return text.substring(0, parseInt(limit)) + '... ';
      }
      return text;
    },
  }
}
</script>
<style>
a.showMoreLink {
  text-transform: uppercase;
  cursor: pointer;
}
</style>
