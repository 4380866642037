<template>
  <div class="container card mt-5" v-if="consultation">
    <div class="row card-body">
      <div class="clinicHeader col-lg-12 col-md-12 col-sm-12">
        <clinic-header :clinic="clinic" />
      </div>
      <div class="summaryTile col-lg-4 col-md-5 col-sm-12 pt-3">
        <div class="serviceDetails percentagePaddingLocalLeft">
          <span class="titleAndAdditionalDetails">
            <strong>{{ consultation.service.name }}</strong>
            <small :style="coloredFonts" class="typeOfSelection" :class="consultation.clinic_package_id ? 'package' : 'service'">
              <span v-if="!consultation.clinic_package_id">Service</span>
              <span v-else>Package <strong>{{order.title}}</strong></span>
            </small>
          </span>
          <div class="serviceDetails mb-3">
            <p><strong>Duration:</strong> {{ consultation.service.duration }} mins</p>
            <p><strong>Price:</strong> {{ consultation.price_display }} </p>
            <p><strong>Service method:</strong> {{ consultation.method === "Standard" ? "In person" : consultation.method }}</p>
          </div>
          <long-text-show-more
            :text="consultation.service.description"
            limit="100"
            :coloredFonts="coloredFonts"
          />
        </div>
        <hr />
        <div class="practitionerDetails percentagePaddingLocalLeft">
          <div class="centerImage">
          </div>
          <span class="titleAndAdditionalDetails">
            <strong>Practitioner: </strong>
            <span>{{ consultation.practitioner.name }}</span>
          </span>
          <long-text-show-more
            :text="consultation.practitioner.community_description"
            limit="100"
            :coloredFonts="coloredFonts"
          />
        </div>
        <hr />
        <div class="dateTimeBlock percentagePaddingLocalLeft">
          <p><strong>Date:</strong> {{ consultation.start | dateOnlyFormat }}</p>
          <p><strong>Time:</strong> {{ consultation.start | dateFormatTime }}</p>
        </div>
      </div>
      <div class="paymentTile col-lg-8 col-md-7 col-sm-12">
        <div v-if="additionalInformation" class="percentagePaddingLocalRight additionalInfo mt-3">
            <strong>Additional information:</strong>
            <long-text-show-more
              :text="additionalInformation"
              limit="100"
              :coloredFonts="coloredFonts"
            />
        </div>
        <hr v-if="additionalInformation" class="mb-0" />
        <div class="percentagePaddingLocalRight securePayment">
          <strong>Secure Card Payment for Consultation #{{ consultation.id }}</strong>
          <p><small>{{ clinic.name }} clinic has chosen Swandoola to process the secure payment, please make payment using secure xyz, once paid you will return to {{ clinic.name}} website.</small></p>
        </div>
        <hr />
        <div class="percentagePaddingLocalRight totalPay">
          <strong>Total to pay:</strong> {{order.currency.symbol}}{{ order.amount_outstanding | currencyFormat }}
        </div>
        <hr />
        <div class="percentagePaddingLocalRight paymentInfo">
          <strong>Payment Information</strong>
        </div>
        <hr v-if="paymentIsDue" />
        <div class="percentagePaddingLocalRight payment" v-if="paymentIsDue">

          <order-payment :order="order"
                         @success="performRedirect" />

        </div>
      </div>
      <div class="buttonFrame col-lg-12 col-md-12 col-sm-12">
        <button
          class="btn myButton float-start"
          @click="redirectBackToSnippet"
          :style="buttonStyle2"
          @mouseover="button2HoverActive()"
          @mouseout="button2HoverInactive()"
          :disabled="busy"
        >
          <busy :visible="busy">Processing Payment</busy>
          <span v-if="!busy">Take me back</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import ClinicHeader from "./partials/consultationPayment/ClinicHeader";
import LongTextShowMore from "./partials/consultationPayment/LongTextShowMore";
import OrderPayment from "../areas/payment-processing/OrderPayment";
export default {
  data() {
    return {
      consultation: null,
      order: null,
      clinic: null,
      redirectTo: '/',
      referrer: '',
      name: '',
      mainColor: '#cccccc',
      fontColor: '#000000',
      busy: false,
      additionalInformation: null,
      button2Hover: false,
      freeServiceText: null,
      isFreeService: false,
    };
  },
  components: {
    OrderPayment,
    LongTextShowMore,
    ClinicHeader,
  },
  computed: {
    coloredFonts() {
        return `color: ${this.mainColor};`
    },
    buttonStyle2() {
      if (this.button2Hover === true) {
        return `background-color: ${this.mainColor}; color: ${this.fontColor};`
      }
      return `background-color: #fff; color: ${this.fontColor};`
    },
    paymentIsDue() {
      if (this.order){
        return this.order.instalments.filter((instalment) => {
          return instalment.status === "not_paid";
        }).length > 0;
      } else {
        return false
      }
    },
  },
  filters: {
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
    dateFormat(date) {
      return moment(date).format("LLL");
    },
    dateFormatAlt(date) {
      return moment(date).format("dddd, Do MMM YYYY");
    },
    dateOnlyFormat(date) {
      return moment(date).format("MMMM DD, YYYY");
    },
    dateFormatTime(date) {
      return moment(date).format("HH:mm");
    }
  },
  methods: {
    button2HoverActive() {
      this.button2Hover = true;
    },
    button2HoverInactive() {
      this.button2Hover = null;
    },
    hexToRgb(hex) {
      if (!hex) {
        return;
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    fetchConsultation() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/consultations/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.consultation = data;
          this.order = data.order;
          // this.order.clinic.charges_enabled = true //@todo remove
          this.clinic = data.order.clinic;
          this.isFreeService = this.consultation.price === 0
        });
    },
    redirectBack() {
      window.location.href = this.getRedirectLocation(false)
    },
    redirectBackToSnippet() {
      window.location.href = this.getRedirectLocation()
    },
    redirectToSite() {
      window.location.href = this.getRedirectLocation()
    },
    getRedirectLocation(snippet = true) {
      let firstParam = '&';
      if (this.redirectTo.lastIndexOf('?') < 0) {
        firstParam = '?';
      }

      //thank you page if snippet false
      let redirectParams = firstParam + "consultation=" + this.consultation.id;
      //back to the booking flow
      if (snippet) {
        redirectParams += '&snippet=1';
      }
      if (this.redirectTo.lastIndexOf(redirectParams) > -1) {
        this.redirectTo = this.redirectTo.replace(redirectParams, '') + redirectParams;
      } else {
        this.redirectTo += redirectParams;
      }
      return this.redirectTo;
    },
    getUrlParams() {
      this.referrer = document.referrer;
      const params = new URLSearchParams(window.location.search);
      if (params.has("redirect")) {
        const redirect = params.get("redirect")
        this.redirectTo = redirect ? decodeURIComponent(redirect) : '';
      }
      if (params.has("scenario")) {
        const scenario = params.get("scenario")
        if (scenario) {
          this.redirectTo += '?scenario=' + decodeURIComponent(scenario);
        }
      }
      //maincolour
      if (params.has("mc")) {
        const mc = params.get("mc")
        this.mainColor = mc ? decodeURIComponent(mc) : ''
      }
      //fontcolour
      if (params.has("fc")) {
        const fc = params.get("fc")
        this.fontColor = fc ? decodeURIComponent(fc) : '';
      }
      //additional information
      if (params.has("adin")) {
        const adin = params.get("adin")
        this.additionalInformation = adin ? decodeURIComponent(adin) : '';
      }
      //free service text
      if (params.has("fsr")) {
        const fsr = params.get("fsr")
        this.freeServiceText = fsr ? decodeURIComponent(fsr) : '';
      }
    },
    performRedirect() {
      const vm = this;
      setTimeout(function() {
        window.location.href =
            vm.redirectTo + "?consultation=" + vm.consultation.id;
      }, 3000);

    },
  },
  created() {
    this.fetchConsultation();
  },
  mounted() {
    this.getUrlParams();
  },
};
</script>

<style>
.myButton {
  background-image: none !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  outline: none !important;
}
.underHr {
  padding-top: 0.5rem;
}
.buttonFrame,
.confirmMessage {
  padding: 0;
  margin-top: 2rem;
}
.percentagePaddingLocalRight {
  padding-left: 7%;
  padding-right: 7%;
}
.percentagePaddingLocalLeft {
  padding-left: 12%;
  padding-right: 13%;
}
.summaryTile .dateTimeBlock {
  margin-bottom: 1rem;
}
.summaryTile .dateTimeBlock p {
  margin: 0;
}
.summaryTile .serviceDetails p {
  margin: 0;
}
.summaryTile .serviceDetails .titleAndAdditionalDetails small {
  font-size: 0.7rem;
  position: relative;
  top: -8px;
}
.summaryTile .serviceDetails .titleAndAdditionalDetails span {
  display: inline-block;
  text-align: left;
  width: 100%;
}
.summaryTile .serviceDetails .titleAndAdditionalDetails {
  font-size: 1rem;
  text-align: left;
  margin-bottom: .5rem;
  display: inline-block;
}
.summaryTile .clinicLogo img {
  margin-bottom: 0;
  margin-top: 1rem;
}
.summaryTile .clinicLogo {
  display: flex;
  justify-content: left;
  align-items: center;
}
.summaryTile .clinicDetails {
  margin-top: 1rem;
}
.paymentTile .card {
  box-shadow: none;
}
.paymentTile .paymentInfo {
  font-size: 1.1rem;
}
.paymentTile .securePayment strong,
.paymentTile .additionalInfo,
.paymentTile .totalPay {
  font-size: 1rem;
}
.paymentTile .securePayment {
  padding-top: 1rem;
  font-size: .9rem;
}
div.paymentTile {
  border-left: 0;
  text-align: left;
}
.paymentTile,
.summaryTile {
  font-size: .75rem;
  border: 1px solid #eee;
  padding: 0;
}
.couponValidate {
  display: inline-block;
}
.bigLabel {
  line-height: 2em;
}
.scrollY {
  overflow-y: auto;
  max-height: 6em;
}
@media (max-width: 753px) {
  .percentagePaddingLocalLeft {
    padding-left: 7%;
    padding-right: 7%;
  }
  div.paymentTile {
    border-top: 0;
    border-left: 1px solid #eee;

  }
}
</style>
