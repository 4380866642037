<template>
  <div class="row percentagePaddingFull">
    <div class="col-md-2 col-sd-2 col-lg-1 img ">
      <img :src="clinic.logo" alt="" />
    </div>
    <div v-if="clinic.name" :class="[clinic.description ? 'col-md-2 col-sd-2 col-lg-3' : 'col-md-10 col-sm-10 col-lg-9', 'name']">
      <h5>{{clinic.name}}</h5>
    </div>
    <div v-if="clinic.description" class="col-md-8 col-sd-8 col-lg-8 description">
      <span>{{clinic.description}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'clinic'
  ]
}
</script>

<style>
.clinicHeader .percentagePaddingFull {
  padding-left: 3%;
  padding-right: 6%;
}
.clinicHeader h5 {
  margin-bottom: 0;
}
.clinicHeader img {
  max-height: 50px;
}
.clinicHeader span {
  font-size: .7rem;
  margin-left: 5%;
}
.clinicHeader > .row {
  border: 1px solid #eee;
  border-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.clinicHeader > .row > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 753px) {
  .clinicHeader .percentagePaddingFull .description {
    padding: 0;
  }
  .clinicHeader .percentagePaddingFull {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  div.clinicHeader span {
    margin-top: 1rem;
    font-size: .6rem;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>